<template>
  <div class="w-100">
    <div class="overflow-auto" style="max-height: 400px">
      <div class="d-flex justify-content-start py-2">
        <h5>로그인 이력</h5>
      </div>
      <div v-if="pending">
        <b-spinner variant="danger" label="LOADING" />
      </div>
      <div v-else>
        <div class="d-flex justify-content-end mb-2">
          <b-form inline v-on:submit.prevent="load">
            <b-form-datepicker
                id="start-date"
                v-model="startDate"
                class="mx-2"
                size="sm"
                locale="ko-KR"
                style="max-width: 320px;"
            />
            <b-form-datepicker
                id="end-date"
                v-model="endDate"
                class="mx-2"
                size="sm"
                locale="ko-KR"
                style="max-width: 320px;"
            />
            <b-button
                type="submit"
                size="sm"
                squared
            >
              <font-awesome-icon
                  icon="search"
              />
            </b-button>
          </b-form>
        </div>
        <div>
          <b-table-simple>
            <colgroup>
              <col width="20%">
              <col>
              <col>
            </colgroup>
            <b-thead>
              <b-tr>
                <b-th scope="col">
                  일자
                </b-th>
                <b-th scope="col">
                  IP
                </b-th>
                <b-th scope="col">
                  상태
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="h in history" :key="`login-history-${h._id}`"
              >
                <b-td>
                  {{ new Date(h.logDate).toLocaleString() }}
                </b-td>
                <b-td>
                  {{ h.ip }}
                </b-td>
                <b-td>
                  {{ h.status }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login-history",
  props: {
    selectedId: String
  },
  data: () => ({
    pending: false,
    startDate: null,
    endDate: null,
    history: []
  }),
  created () {
    (async () => {
      await this.load()
    }) ()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const url = `/admin/users/login-history/${this.selectedId}`
        const { data } = await this.axios({
          url,
          params: {
            startDate: this.startDate,
            endDate: this.endDate
          }
        })

        const { result, startDate, endDate, history, error } = data
        if (result) {
          this.startDate = startDate
          this.endDate = endDate
          this.history = history
          console.log(result, startDate, endDate, history)
        }
      } catch (e) {
      }
      this.pending = false
    }
  }
}
</script>

<style scoped>
  th, td {
    font-size: 0.8rem;
    vertical-align: middle;
  }
</style>
